import Loader from "@/javascript/components/loader"
import { fadeOut } from "@/javascript/components/animations/fade"
import reportError from "@/javascript/components/errors"

const content = document.body
content.classList.add('loading')
let loader = new Loader(content, false)

const load = new Promise((resolve) => {
  window.addEventListener('load', () => load.activatePromise(resolve))

  if(document.readyState === "complete") {
    load.activatePromise(resolve)
  }
})

load.beforeLoad = []
load.beforeLoadRun = false
load.promiseActivated = false

load.activatePromise = (resolve) => {
  if(load.promiseActivated) {
    return
  }
  load.promiseActivated = true

  loader.all().then(() => {
    load.beforeLoadRun = true

    return Promise.all(
      load.beforeLoad.map((fnc) => {
        return new Promise((resolve) => {
          fnc()
          resolve()
        })
      })
    )
    .catch((error) => reportError(error))
    .then(() => {
      const loaderElem = content.querySelector('body > .loader')
      fadeOut(loaderElem, 200)
    })
    .then(() => {
      loader = null
      content.classList.remove('loading')
    })
    .then(resolve)
  })
}

load.before = (fnc) => {
  if(load.beforeLoadRun) {
    fnc()
  } else {
    load.beforeLoad.push(fnc)
  }
}

export default function loadBefore (fnc) {
  load.before(fnc)
}
